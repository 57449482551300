import * as tslib_1 from "tslib";
import axios from 'axios';
import { environment } from 'src/environments/environment';
import { agentUserName, contactId } from './amazonconnect.service';
class Logger {
    log() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!environment.splunkLogEndpoint || !environment.travelAgentsApiAuth)
                return;
            try {
                yield axios.post(environment.splunkLogEndpoint, {
                    query: `
          query ForwardLogging($data: JSON!, $appName: NonEmptyString, $appVersion: NonEmptyString) {
            utility {
              forwardLogging(data: $data, appName: $appName, appVersion: $appVersion) {
                message
                code
              }
            }
          }
        `,
                    variables: {
                        data: this.logData,
                        appName: 'sales-ccp',
                    },
                }, {
                    timeout: 5000,
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': `Basic ${environment.travelAgentsApiAuth}`,
                        'Apollographql-Client-Name': 'sales-ccp',
                    }
                });
            }
            catch (err) {
                console.error('Failed to send log to Splunk', err);
            }
        });
    }
    formatLogData(message, severity, params) {
        this.logData = Object.assign({ messages: message, severity: severity, environment: environment.production ? 'prod' : 'sandbox' }, (agentUserName && { agent: agentUserName }), (contactId && { contactId: contactId }), (params || {}));
    }
    info(message, params) {
        this.formatLogData(message, 'info', params);
        this.log();
    }
    warning(message, params) {
        this.formatLogData(message, 'warning', params);
        this.log();
    }
    error(message, params) {
        this.formatLogData(message, 'error', params);
        this.log();
    }
}
const logger = new Logger();
export default logger;
